import {
  Box,
  Flex,
  Heading,
  Button,
  Image
} from '@chakra-ui/react';
import Logo from "../assets/Logo.png";
import "../assets/styles/navbar.css";
import Login from './Login';
import { useState } from 'react';
import Signup from './Signup';

export default function Navbar() {
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  return (
    <Flex className="nav-wrapper">
      <Login open={loginModal} setOpen={setLoginModal} />
      <Signup open={signupModal} setOpen={setSignupModal} />
      <Image style={{ width: "115px", height: "34px" }} src={Logo} />

      <Flex pl="6%">
        <Heading className={"nav-heading"} size="sm" fontWeight={400}>Trends</Heading>
        <Heading className={"nav-heading"} size="sm" fontWeight={400}>Tags</Heading>
        <Heading className={"nav-heading"} size="sm" fontWeight={400}>Analysis</Heading>
      </Flex>

      <Flex ml="auto">
        <Button className="button1"
          border="1px"
          borderColor="#E41E20"
          bg="black"
          color="white"
          mr="2"
          fontWeight={500}
          onClick={()=>setLoginModal(true)}
        >
          LOG IN
        </Button>

        <Button className="button1"
          bg="#E41E20"
          color="white"
          fontWeight={500}
          onClick={()=>setSignupModal(true)}
        >
          SIGN UP
        </Button>
      </Flex>
    </Flex>
  );
}