import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme';
import { Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './pages/Home';
import "./assets/styles/global.css";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Routes>
        <Route path="/" index element={<Home />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
