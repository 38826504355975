import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useRef } from "react";
import "../assets/styles/modal.css";

export default function Login(props: any) {
    const initialRef = useRef(null)

    return (
        <Modal isOpen={props.open} initialFocusRef={initialRef} onClose={() => {props.setOpen(false)}} isCentered={true}>
            <ModalOverlay />
            <ModalContent className="modal-content">
                <ModalHeader>Log In</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Input ref={initialRef} placeholder='Username' size='lg' marginBottom="3"/>
                    <Input placeholder='Password' type="password" size='lg' />
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={() => { }}>
                        Log In
                    </Button>
                    <Button mr={3} onClick={() => {props.setOpen(false)}}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}