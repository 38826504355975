import { extendTheme, StyleFunctionProps } from '@chakra-ui/react'
import { mode } from "@chakra-ui/theme-tools";


const config = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode('white', 'black')(props),
      }
    })
  }
}

const theme = extendTheme(config)

export default theme