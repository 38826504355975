import { Input } from "@chakra-ui/react";
import bgLogo from "../assets/Isolation_Mode.svg"
import "../assets/styles/home.css";
import sphLogo from "../assets/logo-big.svg";
import Login from "../components/Login";

export default function Home() {

    return (
        <div style={{ width: "100%", background: `url(${bgLogo})`, backgroundRepeat: "no-repeat", backgroundPosition: "right" }}>
            <div className="content-wrapper">
                <img src={sphLogo} style={{margin: "0 auto"}}/>
                <Input placeholder='Search' size='lg' />
                <div className="suggestions-wrapper">
                    <div className="suggestion-wrapper"><a>Sample Suggestion 1 Here</a><a>&gt;</a></div>
                    <div className="suggestion-wrapper"><a>Sample Suggestion 1 Here</a><a>&gt;</a></div>
                    <div className="suggestion-wrapper"><a>Sample Suggestion 1 Here</a><a>&gt;</a></div>
                    <div className="suggestion-wrapper"><a>Sample Suggestion 1 Here</a><a>&gt;</a></div>
                </div>
            </div>
        </div>
    );
}